<template>
  <div
    class="task-item"
    :style="{ 'border-color': qualityIssue.length > 0 && '#eb3f26' }"
  >
    <div class="box-content">
      <div class="logo-box">
        <template v-if="filterTaskType === taskTypeEnum.PRODUCT_TASK">
          <template v-if="taskInfo && taskInfo.dataType === 20">
            <div
              v-if="taskInfo.headImage"
              class="bg-img"
              :style="{backgroundImage: `url(${taskInfo.headImage})`}"
            ></div>
            <img
              v-else
              src="../images/sun.png"
              class="logo"
            />
          </template>
          <img
            v-else
            src="../images/bag.png"
            class="logo"
          />
        </template>
        <img
          v-if="filterTaskType === taskTypeEnum.STORE_TASK"
          src="../images/location.png"
          class="logo"
        />
        <img
          v-if="filterTaskType === taskTypeEnum.FARM_TASK"
          src="../images/pig.png"
          class="logo"
        />
        <img
          v-if="filterTaskType === taskTypeEnum.ACTIVITY_TASK"
          src="../images/activity.png"
          class="logo"
        />
        <img 
          v-if="filterTaskType === taskTypeEnum.SUPPLIER_LEADS"
          src="../images/supplierleads.png"
          class="logo"
        />
        <img
          v-if="filterTaskType === taskTypeEnum.KL_RESEARCH_TASK"
          src="../images/klresearch.png"
          class="logo"
        />
      </div>

      <div class="main-cont">
        <div class="top">
          <p class="task-name">{{ taskName }}</p>
        </div>
        <div class="middle" :class="statusDescClass">
          <div
            v-if="taskStatus !== taskStatusEnum.NEED_SUBMIT"
            class="uploadNum flex-box"
            @click.stop="jumpToDataListPage"
          >
            <div class="flex1">
              <span
                v-if="videoNum !== 0"
                class="upload-item"
              >视频({{ videoNum }})</span>
              <span
                v-if="imageNum !== 0"
                class="upload-item"
              >图片({{ imageNum }})</span>
              <span
                v-if="fileNum !== 0"
                class="upload-item"
              >文件({{ fileNum }})</span>
              <span
                v-if="unknowFileNum !== 0"
                class="upload-item"
              >未知({{ unknowFileNum }})</span>
            </div>
            <div class="flex0" :class="+qualifiedNumber >= +needNumber ? 'black' : 'red'" v-if="filterTaskType === taskTypeEnum.SUPPLIER_LEADS">
              {{ +qualifiedNumber }}/{{ +needNumber }}
            </div>
          </div>
        </div>
        <div
          class="bottom"
          :class="statusDescClass"
        >
          <div class="deadline">
            <div>截止时间：{{ jobEndTime }}</div>
          </div>
          <p class="status-desc">
            <van-button
              v-if="needCheck"
              type="primary"
              color="#3e7bf3"
              size="mini"
              @click.stop="jumpToDataListPage"
            >
              人工检查
            </van-button>
            <span v-else>{{ statusDesc }}</span>
          </p>
        </div>
        <div class="user-info flex-box">
          <div class="flex1 flex-box flex-vertical-center">
            <span class="font icon-user"></span>
            <span class="user-name">{{ taskInfo.userCode }}</span>
          </div>
          <div class="flex0 copy-btn" :class="{'is-copy': !taskInfo.taskAllocationStatus}" @click.stop="copyPath(taskInfo.userTaskId)">
            点击复制
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-tip" v-if="qualityDesc" @click.stop="jumpToDataListPage">
      <div class="invalid-type">{{ qualityDesc }}</div>
        请删除数据后，重新上传。
      <van-icon class="right-arrow" name="arrow" />
    </div>
    <div
      v-if="qualityIssue.length > 0"
      class="quality-issue-list"
    >
      <div
        v-for="(item, index) in qualityIssue"
        :key="index"
        class="quality-issue flex-box flex-vertical-center"
      >
        <p
          v-if="item.title !== null"
          class="title flex0"
        >{{ item.title }}</p>
        <p
          class="content flex1"
          :style="{ 'font-weight': item.title !== null && 'bold' }"
        >{{ item.content }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { taskTypeEnum, taskStatusEnum, taskCheckAuthEnum } from "./config";
import { copyText } from '../shared'
import { confirmAllocation } from '@/apis'
export default {
  name: "TaskItem",
  props: {
    userTaskId: {
      type: Number,
      required: true,
    },
    userTaskIdEncrypt: {
      type: String,
      required: true,
    },
    taskName: {
      type: String,
      required: true,
    },
    jobEndTime: {
      type: String,
      required: true,
    },
    filterTaskType: {
      type: Number,
      required: true,
    },
    taskStatus: {
      type: Number,
      required: true,
    },
    statusDesc: {
      type: String,
      required: true,
    },
    qualityDesc: {
      type: String,
      required: true,
    },
    qualityIssue: {
      type: Array,
      required: true,
    },
    videoNum: {
      type: Number,
      default: 0,
    },
    imageNum: {
      type: Number,
      default: 0,
    },
    fileNum: {
      type: Number,
      default: 0,
    },
    unknowFileNum: {
      type: Number,
      default: 0,
    },
    qualifiedNumber: {
      type: Number,
      default: 0,
    },
    needNumber: {
      type: Number,
      default: 0,
    },
    taskInfo: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      taskTypeEnum,
      taskStatusEnum,
    };
  },
  computed: {
    needCheck() {
      return (
        this.taskStatus === taskStatusEnum.WAIT_CHECK &&
        this.taskInfo.checkAuth === taskCheckAuthEnum.YES
      );
    },
    canJumpToDataListPage() {
      return [
        taskStatusEnum.IN_QUALITY_INSPECTION,
        taskStatusEnum.WAIT_CHECK,
        taskStatusEnum.NO_NEED_QUALITY,
        taskStatusEnum.COMPLETED,
        taskStatusEnum.SUBMITED,
        taskStatusEnum.INVALID,
      ].includes(this.taskStatus)
    },
    statusDescClass() {
      let statusClass = "";
      if ([taskStatusEnum.NEED_SUBMIT, taskStatusEnum.INVALID].includes(this.taskStatus)) {
        statusClass = "status_need_submit";
      } else if (
        this.taskStatus === taskStatusEnum.NO_NEED_QUALITY ||
        this.taskStatus === taskStatusEnum.COMPLETED
      ) {
        statusClass = "";
      } else if (this.canJumpToDataListPage) {
        statusClass = "status_in_quarlity";
      }
      return statusClass;
    },
  },
  methods: {
    /**
     * @description: copy任务详情链接并存储更新缓存
     * @param {*} userTaskId 任务ID
     * @return {*}
     */    
    async copyPath(userTaskId) {
      const detailPath = `${window.location.origin}/elk${this.taskInfo.detailPageUri}`;
      copyText(detailPath);
      this.taskInfo.taskAllocationStatus = true;
      try {
        await confirmAllocation({
          userTaskId,
        })
      } catch (e) {
        console.log(e)
      }
    },
    jumpToDataListPage() {
      if (this.canJumpToDataListPage) {
        this.$router.push({
          path: "/collectDataList",
          query: {
            userTaskId: this.userTaskIdEncrypt, // 加密id
            taskName: this.taskName,
            needCheck: this.needCheck,
          },
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.task-item {
  margin-bottom: 10px;
  background-color: #fff;
  border: 1px solid #cecdcd;
  border-radius: 7px;
  .box-content {
    display: flex;
    padding: 10px 15px;
  }
  .bottom-tip {
    display: flex;
    align-items: center;
    background: #fceeee;
    color: #eb3223;
    padding: 5px 10px;
    font-size: 14px;
    .invalid-type {
      border: 1px solid #eb3223;
      margin-right: 8px;
      padding: 0px 7px;
    }
    .right-arrow{
      margin-left: auto;
    }
  }
  .top {
    display: flex;
    .task-name {
      padding-left: 10px;
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .deadline {
      padding-left: 10px;
      font-size: 12px;
      color: #939292;
    }
    .status-desc {
      flex: none;
    }
  }
  .middle {
    font-size: 12px;
    padding-left: 10px;
    .uploadNum {
      margin-top: 6px;
      color: #333;
      .upload-item {
        margin-right: 5px;
        color: #3e7bf3;
      }
      .red {
        color: #eb3233;
      }
      .black {
        color: #000;
      }
    }
  }
  .status_need_submit {
    .status-desc {
      color: #eb3223;
    }
  }
  .status_in_quarlity {
    .uploadNum {
      color: #3e7bf3;
    }
    .status-desc {
      color: #3e7bf3;
    }
  }

  .quality-issue-list {
    padding: 6px 15px;
    font-size: 12px;
    color: #c7291c;
    background-color: #fceeed;
    border-radius: 7px;
    .quality-issue {
      &:not(:last-child) {
        margin-bottom: 3px;
      }
      .title {
        padding: 0 5px;
        margin-right: 7px;
        border: 1px solid #eb442b;
        max-width: 70px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
p {
  margin: 0;
}
img {
  width: 40px;
  height: 40px;
}
.user-info {
  margin: 5px 0 5px 10px;
  .user-name {
    margin-left: 5px;
    font-size: 12px;
    color: #939292;
  }
  .copy-btn {
    font-size: 12px;
    border: 1px solid #aaa;
    color: #aaa;
    &.is-copy {
      border: 1px solid #3e7bf3;
      color: #3e7bf3;
    }
  }
}

@font-face {
  font-family: "icon-font";
  src: url("./assets/iconfont.eot?t=1635409574237");
  src: url("./assets/iconfont.eot?t=1635409574237#iefix")
      format("embedded-opentype"),
    url("./assets/iconfont.woff?t=1635409574237") format("woff"),
    url("./assets/iconfont.ttf?t=1635409574237") format("truetype"),
    url("./assets/iconfont.svg?t=1635409574237#font") format("svg");
}

.font {
  font-family: "icon-font" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-user::before {
  color: #666;
  content: "\e000";
}

.flex-box {
  display: flex;
  &.flex-vertical-center {
    align-items: center;
  }
  .flex0 {
    flex: none;
  }
  .flex1 {
    flex: 1;
  }
}

.main-cont {
  flex: 1;
}
.bg-img {
  width: 40px;
  height: 40px;
  background: left center no-repeat;
  background-size: cover;
}
</style>
