/*
 * @Author: tongteng
 * @Date: 2023-02-27 14:53:13
 * @LastEditors: tongteng
 * @LastEditTime: 2023-04-20 17:24:16
 * @Description: 请填写简介
 */
export const taskTypeEnum = {
  ALL: -1,
  PRODUCT_TASK: 1, // 商品任务
  STORE_TASK: 2, // 门店任务
  FARM_TASK: 3, // 养猪任务
  ACTIVITY_TASK: 4, // 活动任务
  SUPPLIER_LEADS: 5, // 供应商线索
  KL_RESEARCH_TASK: 6, // KL市调任务
}

export const taskStatusEnum = {
  ALL: -1,
  NEED_SUBMIT: 0, // 未提交
  WAIT_CHECK: 9, // 待检查
  IN_QUALITY_INSPECTION: 6, // 质检中
  COMPLETED: 7, // 已完成
  NO_NEED_QUALITY: 3, // 已完成 - 不需要质检
  SUBMITED: 2, // 已提交
  INVALID: 10, // 质检不合格
}

export const taskCheckAuthEnum = {
  YES: 1,
  NO: 0,
}

export const taskTypeList = [
  { label: '全部', value: taskTypeEnum.ALL },
  { label: '商品任务', value: taskTypeEnum.PRODUCT_TASK },
  { label: '门店任务', value: taskTypeEnum.STORE_TASK },
  { label: '养猪任务', value: taskTypeEnum.FARM_TASK },
  { label: '活动任务', value: taskTypeEnum.ACTIVITY_TASK },
  { label: '线索任务', value: taskTypeEnum.SUPPLIER_LEADS },
]

export const taskStatusList = [
  { label: '全部', value: taskStatusEnum.ALL },
  { label: '未提交', value: taskStatusEnum.NEED_SUBMIT },
  { label: '已提交', value: taskStatusEnum.SUBMITED},
  { label: '待检查', value: taskStatusEnum.WAIT_CHECK },
  { label: '质检中', value: taskStatusEnum.IN_QUALITY_INSPECTION },
  { label: '不合格', value: taskStatusEnum.INVALID },
  { label: '已完成', value: taskStatusEnum.COMPLETED },
]

export const klTaskStatusList = [
  { label: '全部', value: taskStatusEnum.ALL },
  { label: '未提交', value: taskStatusEnum.NEED_SUBMIT },
  { label: '已提交', value: taskStatusEnum.SUBMITED }
]

export const dataTypeEnum = {
  SKU: 1, // SKU采集
  POI: 2, // POI采集
  SKU_CATEGORY_BASELINE: 5, // SKU品类基线采集
  SKU_COUNT_BASELINE: 6, // SKU数量基线采集
  SKU_COUNT_BASELINE_REVIEW: 7, // SKU数量基线复核
}


export const VENDOR_MAP = {
  Other: "其他",
  UnKnown: "unknown",
}

export const SOURCE_TYPE = {
  FILE: 1,
  LINK: 2,
};