<template>
  <div class="task-list">
    <div class="top-bar">
      <form action="/">
        <van-search
          v-model="searchText"
          placeholder="请输入任务标题"
          shape="round"
          :show-action="showAction"
          @focus="showAction = true"
          @blur="showAction = false"
          @search="onSearch"
        />
      </form>
      <dl class="options" v-if="taskTypeList && taskTypeList.length && !isKlResearchTask">
        <dt class="title">类型</dt>
        <dd
          v-for="(item, index) in taskTypeList"
          :key="index"
          :class="{ option: true, active: item.value === taskTypeActive }"
          @click="handleClickOption('taskType', item.value)"
        >
          {{ item.label }}
        </dd>
      </dl>
      <dl class="options">
        <dt class="title">状态</dt>
        <dd class="btn-wrap">
          <div
            v-for="(item, index) in taskStatusList"
            :key="index"
            :class="{ option: true, active: item.value === taskStatusActive }"
            @click="handleClickOption('taskStatus', item.value)"
          >
            {{ `${item.label}(${taskNum[item.value] > 1000 ? '1000+' : taskNum[item.value] || 0})` }}
          </div>
        </dd>
      </dl>
    </div>
    <div
      v-if="showNotice"
      class="notice"
    >
      <div class="content">
        <van-icon name="volume-o" />
        <span>{{ noticeText }}</span>
      </div>
      <van-icon
        name="cross"
        @click="showNotice = false"
      />
    </div>
    <van-pull-refresh
      v-if="!searchLoading"
      v-model="refreshLoading"
      success-text="刷新成功"
      :class="{ 'scroll-wrapper': true, 'no-notice': !showNotice }"
      @refresh="onRefresh"
    >
      <div
        v-if="taskNum[taskStatusActive] > 0"
        class="task-list-wrapper"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          @load="onLoad"
        >
          <task-item
            v-for="item in taskList"
            :key="item.userTaskId"
            :task-info="item"
            :user-task-id="item.userTaskId"
            :user-task-id-encrypt="item.userTaskIdEncrypt"
            :task-name="item.taskName"
            :job-end-time="item.jobEndTime"
            :filter-task-type="item.filterTaskType"
            :task-status="item.status"
            :status-desc="item.statusDesc"
            :quality-desc="item.qualityDesc || ''"
            :quality-issue="item.qualityIssue || []"
            :image-num="item.imageNum"
            :unknow-file-num="item.unknowFileNum"
            :video-num="item.videoNum"
            :file-num="item.fileNum"
            :qualified-number="item.qualifiedNumber"
            :need-number="item.needNumber"
            @click.native="handleClickTask(item)"
          />
          <div
            slot="finished"
            class="finished-text"
          >
            <p>— — 我是有底线的 — —</p>
            <p>只显示有效期内的任务</p>
          </div>
        </van-list>
      </div>
      <van-empty v-else />
    </van-pull-refresh>
    <van-loading
      v-else
      size="24px"
    >加载中....</van-loading>

    <div class="fix-btn">
      <div class="btn-content" :style="{ justifyContent: roleType === roleTypeMap.SUPPER ? 'space-between' : 'flex-start'}">
        <van-popover
          v-if="showAppDownloadBtn && !downloadAppLoading"
          v-model="showPopover"
          :actions="deviceBrands"
          trigger="click"
          placement="top-end"
          @select="onSelectBrand"
        >
          <template #reference>
            <CircleButton>
              <div class="app-download_text">
                <div>App</div>
                <div>下载</div>
              </div>
            </CircleButton>
          </template>
        </van-popover>
        <CircleButton v-else-if="downloadAppLoading" disabled>
          <div class="app-download_text">
            <div>下载中...</div>
          </div>
        </CircleButton>
        <template v-if="roleType === roleTypeMap.SUPPER">
          <van-button
          class="btn-gap"
          type="primary"
          size="small"
          :class="{ 'btn_disabled': downloading }"
          @click="downloadApkExcel"
        >
          {{ `${downloading ? '下载中...' : '明细下载'}`}}
          </van-button>
          <van-button
            class="btn-gap"
            type="primary"
            size="small"
            @click="toUserRegister"
          >
            用户管理
          </van-button>
          <van-button
            class="btn-gap"
            type="primary"
            size="small"
            @click="toTaskAllocate"
          >
            任务调度
          </van-button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant';
import { getUserTaskList, getUserTaskStatusCount, downloadApkExcel, requestBrands, getAppInfo } from "@/apis";
import { redirectUrlByAllocationStrategy, roleTypeMap } from "@/apis/config";
import {
  taskTypeList,
  taskStatusList as normalTaskStatusList,
  klTaskStatusList,
  taskTypeEnum,
  taskStatusEnum,
  VENDOR_MAP,
} from "./config";
import TaskItem from "./task-item.vue";
import { Toast } from "vant";
import { getCookie } from "@/tools/cookie";
import { invalidateToken } from '@/tools/request';
import { sentryReportError, sentryReportMessage } from '../../../tools/sentry';
import { getTokenToObj } from '@/tools/utils';
import deviceDetect from "@/tools/mobile-detect";
import CircleButton from "../components/CircleButton.vue";

const KL_CHANNELID = '273829'

export default {
  name: "TaskList",
  components: {
    TaskItem,
    CircleButton,
  },
  data() {
    return {
      roleTypeMap,
      searchText: "",
      showAction: false, // 是否在搜索框右侧显示取消按钮
      taskTypeList: [], // 任务类型
      normalTaskStatusList, // 任务状态
      klTaskStatusList,
      taskTypeActive: taskTypeEnum.ALL, // 任务类型选中项
      taskStatusActive: taskStatusEnum.ALL, // 任务状态选中项
      taskList: [], // 任务列表
      total: null, // 任务数量
      showNotice: false,
      loading: false, // 上拉加载的loading
      refreshLoading: false, // 下拉刷新的loading
      searchLoading: true, // 搜索的loading
      finished: false,
      isFirstLoad: true,
      // limit: 15,
      limit: 10000, // 待后端搜索功能ok后，再改回15
      offset: 0,
      // TODO 由于目前会返回全量数据，暂时由前端计算，后续需求优化为后端返回，如果后端分页该数值为错误数值
      taskNum: {}, // 不同状态的任务数量，
      scroll: 0,

      channelCode: '',
      showDownloadButton: false,
      deviceBrands: [],
      showAppDownloadBtn: deviceDetect.isMobile && (deviceDetect.isAndroid || deviceDetect.isHarmonyOS),
      showPopover: false,
      downloadAppLoading: false,
      downloading: false,
      roleType: roleTypeMap.COMMON, // 默认普通用户
    };
  },
  computed: {
    // 是否是KL市调任务类型，快驴专属的channelCode是273829
    isKlResearchTask() {
      return this.channelCode === KL_CHANNELID;
    },
    taskStatusList() {
      return this.channelCode === KL_CHANNELID ? this.klTaskStatusList : this.normalTaskStatusList
    }
  },
  activated() {
    document.title = "";
    const $el = document.getElementsByClassName('scroll-wrapper')[0]
    if (this.scrollTop) {
      $el.scrollTop = this.scrollTop
    }
  },
  beforeRouteEnter(to, from, next) {
    const path = to.path;
    redirectUrlByAllocationStrategy(path)
    next()
  },
  beforeRouteLeave(to, from, next) {
    const $el = document.getElementsByClassName('scroll-wrapper')[0];
    if($el){
      this.scrollTop = $el?.scrollTop;
    }
    next()
  },
  async mounted() {
    this.getTaskStatusCount();
    this.channelCode = getCookie("channelCode");
    this.getTaskList({ isFirstLoad: true, isRefresh: false });
  },
  created(){
    const { roleType } = getTokenToObj() || {};
    this.roleType = +roleType || roleTypeMap.COMMON;
    if(this.showAppDownloadBtn){
      this.requestBrands();
    }
  },
  methods: {
    async getTaskStatusCount() {
      try {
        const res = await getUserTaskStatusCount();
        if (res && res.code === 0) {
          const { qualityUnPassCount = 0 } = res.data || {};
          if (!qualityUnPassCount || qualityUnPassCount === 0) {
            this.showNotice = false;
            return;
          }
          this.showNotice = true;
          this.noticeText =`质检不合格任务 x ${ qualityUnPassCount }，请尽快处理。`;
        } else {
          Toast.fail(res.msg || "获取数字状态统计失败");
        }
      } catch (e) {
        Toast.fail("获取数字状态统计失败");
      }
       
    },
    async getTaskList({ isFirstLoad = true, isRefresh = false }) {
      // isFirstLoad: true, isRefresh: true, 下拉刷新
      // isFirstLoad: true, isRefresh: false, 搜索
      // isFirstLoad: false, 上拉加载
      if (isFirstLoad) {
        this.offset = 0;
        // 下拉刷新时，需将finished置为true，否则会触发一次额外的上拉加载(bug)
        // 搜索时，则将searchLoading置为true
        if (isRefresh) {
          this.finished = true;
        } else {
          this.searchLoading = true;
        }
      } else {
        this.offset += this.limit;
      }
      const params = {
        filterTaskType:
          this.taskTypeActive === taskTypeEnum.ALL
            ? undefined
            : this.taskTypeActive,
        taskStatus:
          this.taskStatusActive === taskStatusEnum.ALL
            ? undefined
            : this.taskStatusActive,
        titleKeyword: this.searchText,
        limit: this.limit,
        offset: this.offset,
        backTrackFlag: 1, // 反溯源链路传1
      };
      try {
        const res = await getUserTaskList(params);
        if (res && res.code === 0) {
          const { data = [], total, downloadButtonFlag = false, statusCountMap = {}} = res.data || {};
          // 给全部赋值
          statusCountMap[taskStatusEnum.ALL] = total;
          this.taskNum = statusCountMap
          this.total = statusCountMap[this.taskStatusActive];
          this.showDownloadButton = downloadButtonFlag;
          this.taskList = isFirstLoad ? data : [...this.taskList, ...data];
          // 以下代码，待后端搜索功能ok后删除
          if (this.searchText !== "") {
            this.taskList = this.taskList.filter((item) =>
              item.taskName.includes(this.searchText)
            );
            this.total = this.taskList.length;
          }
          
          
          // 计算当类型和状态均为全部时，展示哪些类型
          if (this.taskTypeActive === taskTypeEnum.ALL && this.taskStatusActive === taskStatusEnum.ALL) {
            const allTypeList = [-1].concat(this.taskList.map(item => item.filterTaskType))
            this.taskTypeList = taskTypeList.filter(item => {
              return allTypeList.includes(item.value)
            })
          }
        } else {
          if (!isFirstLoad) {
            this.offset -= this.limit;
          }
          if (res.code === 107) {
            invalidateToken()
            if (!this.channelCode) {
              Toast.fail("登录失效，请重新扫码");
            } else {
              Toast.fail(res.msg || "获取任务列表失败");
              setTimeout(() => {
                this.$router.push({
                  path: `/login?channelCode=${this.channelCode}`,
                  replace: true,
                });
              }, 2000);
            }
          } else {
            Toast.fail(res.msg || "获取任务列表失败");
          }
        }
      } catch (e) {
        Toast.fail("获取任务列表失败");
      } finally {
        this.loading = false;
        this.searchLoading = false;
        this.refreshLoading = false;
        this.finished = this.taskList.length >= this.total;
      }
    },
    onSearch() {
      this.getTaskList({ isFirstLoad: true, isRefresh: false });
      this.showNotice && this.getTaskStatusCount();
    },
    onRefresh() {
      this.getTaskList({ isFirstLoad: true, isRefresh: true });
      this.showNotice && this.getTaskStatusCount();
    },
    onLoad() {
      this.getTaskList({ isFirstLoad: false, isRefresh: false });
    },
    async requestBrands(){
      try {
        const response = await requestBrands();
        if(response && response.code === 0){
          this.deviceBrands = (response.data || []).map(item => {
            return { text: item.brand, ...item };
          });
        } else {
          Toast.fail("获取厂商失败，请刷新页面重试！");
        }
      } catch (error) {
        Toast.fail("获取厂商失败，请刷新页面重试！");
      }
    },
    handleClickOption(type, value) {
      if (type === "taskType" && this.taskTypeActive !== value) {
        this.taskTypeActive = value;
        this.onSearch();
      } else if (type === "taskStatus" && this.taskStatusActive !== value) {
        this.taskStatusActive = value;
        this.onSearch();
      }
    },
    handleClickTask(item) {
      let detailAuth = item.detailAuth; // 详情页权限 无-0 有-1
      if (detailAuth) {
        let detailPageUri = item.detailPageUri;
        this.$router.push({ path: detailPageUri });
      } else {
        Toast.fail("您无权查看任务详情页面");
      }
    },
    async downloadApkExcel(){
      /**下载apk采集进度明细数据 */
      if(this.downloading){
        return;
      }
      this.downloading = true;
      try {
        const res = await downloadApkExcel();
        if(res && res.code === 0 && res.data){
          window.location.href = res.data;
        } else {
          Toast.fail(res.msg || '下载失败，请稍后重试');
        }
      } catch (error) {
        Toast.fail(error.message || '下载失败，请稍后重试');
        sentryReportError(new Error('apk明细下载失败'), {
          reason: error?.message
        });
      } finally {
        this.downloading = false;
      }
    },
    toUserRegister() {
      this.$router.push({ path: '/userRegister' });
    },
    toTaskAllocate() {
      this.$router.push({ path: '/taskAllocate' });
    },
    async onSelectBrand(action){
      const { vendor, ua, os, browser } = deviceDetect.mobileDetect();
      if(
        vendor === VENDOR_MAP.UnKnown ||
        action.brand?.toLowerCase() === vendor ||
        (action.brand === VENDOR_MAP.Other && !this.deviceBrands.map(v => v.brand?.toLowerCase()).includes(vendor))
      ){
        try {
          await Dialog.confirm({
            title: `是否下载${ action.brand }型号的APP`,
            message: "请确认已卸载非本渠道下载的APP"
          });
          this.downloadAPP(action);
          if(vendor === VENDOR_MAP.UnKnown){
            sentryReportMessage("vendor未知", "warning", {
              browser,
              os,
              userAgnet: ua,
              selected: action.brand,
            })
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        Dialog.alert({
          title: "手机型号不匹配，下载失败"
        });
      }
    },
    async downloadAPP(action){
      Toast("正在下载，请稍等...");
      const { brand, project } = action || {};
      this.downloadAppLoading = true;
      try {
        const response = await getAppInfo({ brand, project });
        if(response && response.code === 0){
          const { url } = response.data || {};
          if(url){
            window.location.href = url;
          }
        } else {
          Toast.fail(response.msg || "下载失败，请重试！");
        }
      } catch (error) {
        Toast.fail(error.message || "下载失败，请重试！");
      } finally {
        this.downloadAppLoading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.task-list {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
}
.top-bar {
  flex: none;
  padding-bottom: 15px;
  background-color: #fff;
  dl,
  dd {
    margin: 0;
  }
  .options {
    display: flex;
    align-items: center;
    padding: 0 5px;
    font-size: 12px;
    &:nth-of-type(1) {
      margin-top: 5px;
    }
    &:nth-of-type(2) {
      margin-top: 10px;
    }
    .title {
      display: flex;
      align-self: flex-start;
      justify-content: center;
      width: 40px;
      height: 27px;
      font-size: 14px;
      font-weight: bold;
      line-height: 27px;
    }
    .btn-wrap {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      align-items: flex-start;
      width: 0;
    }
    .option {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 60px;
      height: 27px;
      margin-bottom: 5px;
      margin-left: 10px;
      background-color: #f7f7f7;
      border-radius: 5px;
      &:nth-of-type(1) {
        width: 40px;
      }
      &.active {
        color: #fff;
        background-color: #4badf5;
      }
    }
  }
}
.notice {
  display: flex;
  flex: none;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  padding: 0 15px;
  font-size: 14px;
  color: #d13622;
  background-color: #fbe8d5;
  .content {
    display: flex;
    align-items: center;
    font-size: 13px;
  }
}
.scroll-wrapper {
  flex: 1;
  padding: 10px 15px 15px;
  overflow: auto;
  font-size: 14px;
  background-color: #f5f5f5;
  &.no-notice {
    top: 138px;
  }
  .task-total {
    padding-bottom: 10px;
    color: #767676;
  }
  .task-list-wrapper {
    padding-bottom: 20px;
    overflow-y: auto;
  }

  .finished-text {
    p {
      margin: 0;
      &:nth-of-type(2) {
        font-size: 12px;
      }
    }
  }
}
.van-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
}

/deep/ .van-list__finished-text {
  line-height: 0.5rem;
}
.fix-btn{
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    box-shadow: 0 -4px 14px 0 rgba(0,0,0,.04);
    .btn-content{
        width: 100%;
        height: 60px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        padding: 18px 24px;

        .app-download_text {
          display: flex;
          flex-direction: column;
        }
    }
    .btn-gap{
        box-sizing: border-box;
        text-align: center;
        font-size: 14px;
        color: #ffffff;
        border-radius: 48px;
        background: #539cfe;
        line-height: 14px;
        border: none;
    }
}
</style>
